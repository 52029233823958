import React from 'react';
import './our-founders.scss';
import logo from '../../../assets/logo-anim.gif';
import BackBtn from '../../../components/back-btn/index.js';
import founders from '../../../assets/founders.png';
import Footer from '../../../components/footer/index.js';

const OurFounders = () => {
    return (
        <div className="our-founders">
            <BackBtn to="/about" className="back-btn"/>
            <a href="/home">
                <img className="header-logo" src={logo}/>
            </a>

            <div className="our-founders-content">
                <div id="profiles">
                    <div className="profile" id="smooky">
                        <div className="name timeline-regular-normal-white-45px">Smooky MarGielaa</div>
                        <div className="bio timeline-regular-normal-white-17px">Smooky MarGielaa is a recording artist from The Bronx, New York. At 15 years old, he became a member of A$AP Rocky’s creative agency ‘AWGE’ and began working closely with music group ‘A$AP Mob’. By age 16, he had released hits like 'Flight to Memphis', 'Stay "100"', and 'Vlone Flex', helping the young rapper to quickly gain over 50 million streams on various platforms. Now independent at age 20, Smooky has collaborated with the likes of Beyonce, Diplo, Playboi Carti, Chris Brown, Lil Yachty, and more. In anticipation of his debut album ‘Margielaa Mad Man’, he has overcome legal controversy to release tracks like ‘Mamacita’ and ‘Chit Chat’ alongside A$AP Rocky and French Montana. Smooky Margielaa has a constantly evolving sound, possessing a range in lyricism and vocals that allow him to skillfully perform within any given genre.</div>
                        <div className="social-links">
                            <div>
                                <a href="https://open.spotify.com/artist/2HO2kO7O5gEnM91dhobllP?si=_ZK4SJeSSvShXHnbfpTAdA" target="_blank" className="link1 futura-medium-white-22px">SPOTIFY</a>
                            </div>
                            <div>
                                <a href="https://www.instagram.com/whois.smookymargielaa/" target="_blank" className="link2 futura-medium-white-22px">INSTAGRAM</a>
                            </div>
                        </div>
                    </div>

                    <div className="profile" id="ryan">
                        <div className="name timeline-regular-normal-white-45px">Ryan Kessler</div>
                        <div className="bio timeline-regular-normal-white-17px">Ryan Kessler is in charge of daily operations for Mad Man Entertainment. As a teenager, Ryan first met Smooky MarGielaa at a Manhattan recording studio. From there, a rapport developed and translated into a musical venture, and within a year, Smooky performed at a concert coordinated by Ryan in Brooklyn, New York. Today, the two run an entertainment company together, where Ryan is responsible for managing collaborations between artists, concert coordination, creating apparel lines, directing content creation, scouting talent, and serving as executive producer on all native releases - overseeing and physically editing everything from musical composition to cover art. Whether he’s in the studio, at an event, or directing, Ryan has an absolute ability to bring together artists with collaborative promise.</div>
                        <div className="social-links">
                            <div>
                                <a href="mailto:ryan@madmanent.com" target="_blank" className="link1 futura-medium-white-22px">EMAIL</a>
                            </div>
                            <div>
                                <a href="https://www.instagram.com/ryan.kessler_/" target="_blank" className="link2 futura-medium-white-22px">INSTAGRAM</a>
                            </div>
                        </div>
                    </div>
                </div>

                <img className="img28" src={founders} />
            </div>

            <div className="our-founders-footer">
                <Footer/>
            </div>
        </div>
    );
};

export default OurFounders;
