import React from 'react';
import './home.scss';
import logo from '../../assets/logo-static.png';
import music from '../../assets/music.gif';
import concerts from '../../assets/concerts.gif';
import about from '../../assets/about.gif';
import shop from '../../assets/shop-icon.gif';
import Footer from '../../components/footer/index.js';

const Home = () => {
    return (
        <div className="home">
            <img className="static-logo" src={logo} />

            <div className="nav">
                <a className="section-link about-btn" href="/about">
                    <img src={about} />
                    <span className="timeline-regular-normal-white-25px">ABOUT</span>
                </a>
                <a className="section-link music-btn" href="/music">
                    <img src={music}/>
                    <span className="timeline-regular-normal-white-25px">MUSIC</span>
                </a>
                <a className="section-link concerts-btn" href="/concerts">
                    <img src={concerts} />
                    <span className="timeline-regular-normal-white-25px">CONCERTS</span>
                </a>
                <a className="section-link" target="_blank" href="https://www.madmanent.store">
                    <img src={shop} />
                    <span className="timeline-regular-normal-white-25px">SHOP</span>
                </a>
            </div>

            <div className='home-footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default Home;