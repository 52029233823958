import React from 'react';
import './back-btn.scss';

const BackBtn = (props) => {
    return (
        <a className="back-btn" href={props.to}>
            {/* <svg
                className="back-btn__icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
            </svg> */}
            BACK
        </a>
    );
}

export default BackBtn;