import React, { useState, useEffect} from "react";
import './intro.scss'
import logoAnim from '../../assets/logo-anim.gif';
import arrow from '../../assets/arrow.png';
import video from '../../assets/home-video.mp4';
import poster from '../../assets/home-video-cover.png';
import Footer from '../../components/footer/index.js';
import logoStatic from '../../assets/logo-static.png';

import rw from '../../assets/rw.png';
import ff from '../../assets/ff.png';
import pause from '../../assets/pause.png';
import play from '../../assets/play.png';


const Intro = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const videoRef = React.createRef();

    const fastForward = () => {
        if(videoRef.current.currentTime + 10 < videoRef.current.duration) {
            videoRef.current.currentTime += 10;
        }else{
            videoRef.current.currentTime = videoRef.current.duration;
        }
    };

    const pausePlay = () => {
        if(videoRef.current.paused) {
            videoRef.current.play();
        } else {
            videoRef.current.pause();
        }
    };
    
    const rewind = () => {
        if(videoRef.current.currentTime > 0) {
            videoRef.current.currentTime = 0;
        }
    };

    const scroll = () => {
        document.getElementById("video").scrollIntoView({behavior: "smooth"});
        var video = document.getElementById("video");
        video.play();
    }

    return (
        <div className="landing-page">
            <img className="landing-logo" src={logoAnim} />
            
            <div>
                <div className="enter-button mobile-enter-btn">
                    <a href="/home" className="enter-site">Enter Site</a>
                </div>
            </div>
        </div>
    );
};

export default Intro;