import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";

const Footer = () => {
    return (
        <div className="footer">
            <a href="/terms-and-conditions">Terms and conditions</a>
            <p>&copy; 2023 Mad Man Ent. LLC</p>
        </div>
    );
};

export default Footer;