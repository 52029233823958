import './App.scss';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Intro from './pages/intro/index.js';
import Home from './pages/home/index.js';
import Music from './pages/music';
import Concerts from './pages/concerts';
import About from './pages/about';
import CompanyInitiative from './pages/about/company-initiative';
import OurFounders from './pages/about/our-founders';
import TermsAndConditions from './pages/terms-and-conditions';
import Lock from './pages/lock';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Intro/>}></Route>
        {/* <Route path="/" element={<Lock/>}></Route> */}
        <Route path="/home" element={<Home/>}></Route>
        <Route path="/music" element={<Music/>}></Route>
        <Route path="/concerts" element={<Concerts/>}></Route>
        <Route path="/about" element={<About/>}></Route>
        <Route path="/our-founders" element={<OurFounders/>}></Route>
        <Route path="/company-initiative" element={<CompanyInitiative/>}></Route>
        <Route path="/terms-and-conditions" element={<TermsAndConditions/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
