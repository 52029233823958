import React from 'react';
import './about.scss';
import logo from '../../assets/logo-static.png';
import founders from '../../assets/founders.gif';
import cieInitiative from '../../assets/cie-initiative.gif';
import BackBtn from '../../components/back-btn/index.js';
import Footer from '../../components/footer/index.js';

const About = () => {
    return (
        <div className="about">

            <BackBtn to="/home" className="back-btn"/>
            <a href="/home">
                <img className="header-logo" src={logo}/>
            </a>
            <div className='link-row'>
                <a className='about-link' href="/our-founders">
                    <img src={founders}/>
                    <span className="timeline-regular-normal-white-25px">OUR FOUNDERS</span>
                </a>
                <a className='about-link' href="/company-initiative">
                    <img src={cieInitiative}/>
                    <br/>
                    <span className="timeline-regular-normal-white-25px">COMPANY INITIATIVE</span>
                </a>
            </div>
            <div className='contact-btn'>
                <a href="mailto:ryan@madmanent.com">
                    CONTACT US
                </a>
            </div>

            <div className='about-footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default About;


