import './concerts.scss';
import React, { useState } from 'react';
import logo from '../../assets/logo-anim.gif';
import BackBtn from '../../components/back-btn';
import LazyLoad from 'react-lazyload';
import Footer from '../../components/footer';
import ddgPoster from '../../assets/DDG.JPEG';
import twinsickPoster from '../../assets/twinsick.jpeg';

const Concerts = () => {
    return (
        <div className="concerts">
            <BackBtn to="/home" className="back-btn"/>
            <a href="/home">
                <img className="header-logo" src={logo}/>
            </a>

            <div className='concerts-container'>
                <div className='posters-container'>
                    <img src={ddgPoster}/>
                    <img src={twinsickPoster}/>
                </div>
                <div className='text-container'>
                    <p>
                        We coordinate our own concerts and provide concert throwing services for those who want to produce live events in their city or at their school.
                    </p>
                    <p>
                        At Mad Man Entertainment, we understand that music is a powerful force that can bring people together and create unforgettable experiences. That is why our team is dedicated to providing top-notch concert coordination services, ensuring that every detail is taken care of from start to finish.
                    </p>
                    <p>
                        From booking the venue and the artists to promoting the event and managing ticket sales, we handle everything so that you can sit back and enjoy the show. Whether you are a student organization looking to host a campus concert, a greek life organization throwing a private concert, an individual producing a public show, or a local business hoping to throw a community event, we have the expertise and resources to make your vision a reality.
                    </p>
                    <p>
                        We work closely with our clients to understand their goals and preferences, offering customized solutions that are tailored to their needs. With our extensive network of industry connections and years of experience in event planning and coordination, we are confident in our ability to deliver exceptional results every time.
                    </p>
                    <p>
                        We are committed to providing the highest level of service and professionalism, and we take pride in our ability to craft impactful experiences through the production of live arts.
                    </p>
                    <p>
                        Contact us today to learn more about our concert coordination services.
                    </p>

                    <div className='concerts-contact'>
                        <a href="mailto:ryan@madmanent.com">
                            CONTACT US
                        </a>
                    </div>
                </div>

            </div>

            <Footer/>
        </div>
    );
}

export default Concerts;
