import React from "react";
import "./terms-and-conditions.scss";
import BackBtn from "../../components/back-btn";
import logo from "../../assets/logo-anim.gif";
import Footer from "../../components/footer";

const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions">
            <BackBtn to="/home" className="back-btn"/>
            <a href="/home">
                <img className="header-logo" src={logo}/>
            </a>
            <p>Terms and Conditions</p>
            <ol>
                <li> 
                    <b>Terms and Conditions</b> 
                    <br/>
                    Welcome to Mad Man Ent. LLC's website ("the Site"). By accessing or using the Site, you agree to be bound by these terms and conditions ("Terms"). Please read them carefully before using the Site.                </li>
                <li>
                    <b>Ownership and Use of Content</b>
                    <br/>
                    All of the content on the Site, including but not limited to text, graphics, logos, images, and software, is the property of Mad Man Ent. LLC or its licensors and is protected by copyright laws. You may use the content on the Site only for your personal, non-commercial use. You may not modify, reproduce, distribute, display, or create derivative works based on any of the content on the Site without the prior written consent of Mad Man Ent. LLC.
                </li>
                <li>
                    <b>All Sales are Final</b>
                    <br/>
                    All sales on the Site are final. We do not offer exchanges, returns or refunds. Please carefully review your order before submitting it.                </li>
                <li>
                    <b>Shipping Policy</b>
                    <br/>
                    After placing an order on the Site, you will receive an email confirmation with the details of your order. If you have any questions or concerns about your order, please email info@madmanent.com. We are not responsible for any lost, stolen, or damaged goods during shipping. If your item arrives damaged, please contact the shipping carrier to file a claim.
                </li>
                <li>
                    <b>Termination</b>
                    <br/>
                    Mad Man Ent. LLC reserves the right to terminate or suspend your access to the Site at any time, with or without notice, for any reason or no reason at all.
                </li>
                <li>
                    <b>Limitation of Liability</b>
                    <br/>
                    In no event shall Mad Man Ent. LLC be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use the Site or the content on the Site.
                </li>
                <li>
                    <b>Governing Law and Jurisdiction</b>
                    <br/>
                    These Terms and any dispute arising out of or related to these Terms or the Site shall be governed by and construed in accordance with the laws of the State of New York, without giving effect to any choice or conflict of law provision.
                    <br/>
                    <br/>
                    By using the Site, you agree to these Terms and the policies outlined in them. If you do not agree to these Terms, please do not use the Site. We reserve the right to modify these Terms at any time, without notice. Your continued use of the Site after any such modification constitutes your acceptance of the modified Terms.
                </li>
            </ol>

            <div className='terms-footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default TermsAndConditions;