import React from 'react';
import './company-initiative.scss';
import logo from '../../../assets/logo-anim.gif';
import BackBtn from '../../../components/back-btn/index.js';
import Footer from '../../../components/footer/index.js';

const CompanyInitiative = () => {
    return (
        <div className="company-initiative">
            <BackBtn to="/about" className="back-btn"/>
            <a href="/home">
                <img className="header-logo" src={logo}/>
            </a>
            <p className='timeline-regular-normal-white-25px initiative-declaration'>
                Welcome to Mad Man Entertainment. A venture established to create high quality art and entertainment in an equitable and just manner.
            </p>
            <p className='timeline-regular-normal-white-25px initiative-declaration'>
                Art is created to be seen; to be heard. Greedy corporations have transformed its beauty into a mere commodity for profit. They’ve enslaved creators and stolen their livelihoods. At Mad Man Ent., our mission is to create and entertain without the abusive exploitation of talent that plagues our industry.
            </p>
            <p className='timeline-regular-normal-white-25px initiative-declaration'>
                We strive to reinvent the dynamic of an artist-company relationship. The current transactional model that exists is flawed and outdated; ownership of art is unrightfully taken as currency for opportunity and promotion. Musicians are treated as products, not creators. We are here to foster creation and collaboration, not to deceive and profit. 
            </p>
            <p className='timeline-regular-normal-white-25px initiative-declaration'>
                The present state of our industry is not something we can combat overnight, but we are a force to push the battle in the right direction. We are fighting for the freedom of artists, for the betterment of art and entertainment, and to incite the dawn of a revolution.            </p>
            <p className='timeline-regular-normal-white-25px initiative-declaration'>
                Mad Man Entertainment: a blissful mission executed with ruthless conviction. 
            </p>

            <div className='company-initiative-footer'>
                <Footer/>
            </div>
        </div>
    );
};

export default CompanyInitiative;
